<template>
    <div tabindex="0" :id="'popover-shift-' + _uid" class="app-planner-shifts-block app-planner-shifts-block-focus" 
         :style="{'width': (block_clone.width*minute_pixel_width) + 'px', 'left': (hours_from_start*minute_pixel_width) + 'px'}" v-if="show_blocks"
         v-bind:class="{'app-planner-shifts-block-registration-danger':  (block.block_type == 1 && (block.status < 0 || block.status == null)) || (block.block_type == 2 && block.registered == 1),
                        'app-planner-shifts-block-registration-success': (block.block_type == 1 && block.status == 1) || (block.block_type == 2 && block.registered == 0),
                        'app-planner-shifts-block-registration-warning': block.block_type == 1 && block.status == 0}"> 
        <b-popover :target="'popover-shift-' + _uid" triggers="focus" @show="block.block_type == 1 ? getShiftData() : getAssignmentData()">
            <template #title>
                 <p class="mb-0 app-popover-small-text font-weight-600">{{block.block_type == 1 ? trans('shift',209) : trans('detachment',182)}}</p>
            </template>            
            <div class="text-center mt-2 mb-3 text-muted" v-if="loading">
                {{trans('planning-loading-data',211)}}
            </div>
            <b-row class="align-items-center" v-else>
                <b-col cols="5" class="pr-0" start>
                    <p class="app-popover-small-text mb-2">{{trans('status',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-from',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-to',3)}}</p>
                    <p class="app-popover-small-text mb-2">{{trans('function',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('department',182)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('location',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('client',5)}}</p>
                    
                </b-col>
                <b-col cols="7" class="pl-0" end>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-2">{{ data != null && data.status_name ? data.status_name : '-'}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ data != null && data.date_from ? data.date_from : '-'}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ data != null && data.date_to ? data.date_to : '-'}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-2">{{ data != null && data.function_name ? data.function_name : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ data != null && data.department_name ? data.department_name : '-'}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0 ">{{ data != null && data.location_name ? data.location_name : '-'}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ data != null && data.client_name ? data.client_name : '-'}}</p>
                </b-col>
            </b-row>
            
            <hr class="mt-1 mb-2" />

            <div class="text-center">
                <app-button type="secondary-grey" :url="block.block_type == 1 ? { name: 'planning-shifts-profile', params: { id_shift_data_main: block.id_shift_data_main }} : { name: 'planning-assignments-profile', params: { id_assignment_data_main: block.id_assignment_data_main }}" :loading="loading">{{block.block_type == 1 ? trans('to-service',200) : trans('pl-to-secondment',210)}}</app-button>
            </div>
            
        </b-popover>  
        
    </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";

export default {
    name: 'PlanningPlannerBlocksShiftsComponent',
    
    computed: { 

    },

    props:["block", "read_only", "minute_pixel_width", "week_first_day"],
    
    data() {
        return { 
            data: null,
            loading: false,
            hours_from_start: null,
            show_blocks: false,
            refresh:0,
            block_clone:null
        };
    },

    created(){
        this.block_clone = JSON.parse(JSON.stringify(this.block));
        this.calculateBlocks();  
    },

    watch:{
        "week_first_day": {
            handler: function(val) {
                this.show_blocks = false;
                this.calculateBlocks();           
            },
            deep:true
        }
    },
    
    methods: {
        calculateBlocks(){
            let week_start_date = moment(this.week_first_day + ' ' + '00:00:00', 'DD-MM-YYYY hh:mm:ss');
            let week_end_date = moment(this.week_first_day + ' ' + '23:59:59', 'DD-MM-YYYY hh:mm:ss').add(6, 'd');
            let block_start_date = moment(this.block.plan_date_from, 'YYYY-MM-DD hh:mm:ss');
            let block_end_date = moment(this.block.plan_date_to, 'YYYY-MM-DD hh:mm:ss');
            let width = 0;

            if(block_start_date < week_start_date && block_end_date >= week_start_date){
                block_start_date = week_start_date;
            }

            if(block_end_date > week_end_date) {
                block_end_date = week_end_date;
            }
            if(this.block.block_type == 2 && block_start_date < week_start_date){
                block_start_date = week_start_date;
            }
            this.hours_from_start = moment.duration(block_start_date.diff(week_start_date))
            this.hours_from_start = this.hours_from_start.asHours() * 60;

            width =  moment.duration(block_end_date.diff(block_start_date));
            this.block_clone.width = width.asHours() * 60;

            if(block_start_date.isBetween(week_start_date, week_end_date) || block_end_date.isBetween(week_start_date, week_end_date) || (block_start_date <= week_start_date && block_end_date >= week_end_date)){
                return this.show_blocks = true;
            }
            
        },
        
        getShiftData() {
            this.loading = true;
            this.data = null;
            axios
                .get('planning/planner/getShiftPopover/'+ this.block.id_shift_data_main)
                .then((res) => {
                    this.data = res.data
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getAssignmentData() {
            this.loading = true;
            this.data = null;
            axios
                .get('planning/planner/getAssignmentPopover/'+ this.block.id_assignment_data_main)
                .then((res) => {
                    this.data = res.data
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

          
    }
};
</script>

<style scoped>
</style>
