<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fp-unavailability-add', 172)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>
                <app-row-left-label :label="trans('spieces', 182)">
                    <app-select v-model="type" type="getAvailabilityTypes" validatorRules="required" :validatorName="trans('spieces', 182)" />
                </app-row-left-label>

                <b-alert variant="warning" show class="mb-3" v-if="type && type.value == 4">
                    <div class="text-md-start mb-2"> {{ trans('fw-sick-allert-info', 177) }}</div> 
                </b-alert> 

                <app-row-left-label :label="trans('date-from', 182)">
                    <app-date v-model="date_from" validatorRules="date-check|required" :validatorName="trans('date-from', 182)" :disabled="!type"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('date-to', 182)">
                    <app-date v-model="date_to" validatorRules="date-check|required" :disabledDatesPass="cDisabledDates" :validatorName="trans('date-to', 182)" :disabled="!type"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('sh-hours', 215)">
                    <app-input v-model="hours_amount" type="decimal_places_02" :validatorName="trans('sh-hours', 215)" :validatorRules="'app-max-value:' + cMaxHours" :disabled="!type"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('description', 182)">
                    <app-memo v-model="description" :disabled="!type"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="add" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationObserver } from "vee-validate";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result", "profile"],

    created(){
        if (this.args != null && this.args.selected_date != null) {
            this.date_from = this.args.selected_date;
            this.date_to = this.args.selected_date;
        }
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cDisabledDates: {
            get() {
                var today = moment(moment.now());
                var d = today.diff(moment(this.date_from, 'DD-MM-YYYY'), 'days');
                return d >= 0 ? d + 1 : d;
            }
        },

        cMaxHours: {
            get(){
                var date_from = this.roundDate(moment(this.date_from + '00:00', 'DD-MM-YYYY HH:mm'));
                var date_to = this.roundDate(moment(this.date_to + '23:59', 'DD-MM-YYYY HH:mm'));

                var diff = date_to.diff(date_from, 'minutes')/60;
                return diff.toFixed(2);
            }
        },

        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
        return {
            loading: false,

            date_from: null,
            date_to: null,
            type: null,
            description: null,
            hours_amount: null,
            alert_class: new AppAlerts(),
        };
    },

    methods: {
        add() {  

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                } else {
                    
                    let selected = false;
                    if(this.cArgs.return_type == 2){
                        selected = this.cReturn.selected;
                    }

                    this.loading = true;
                    axios
                        .post('planning/planner/unavailability/add', {
                            id_person_data_main: this.args.id_person_data_main,
                            filters: JSON.stringify(this.args.filters),
                            date_from: this.date_from,
                            date_to: this.date_to,
                            hours_amount: this.hours_amount,
                            id_availability_const_type: this.type.value,
                            description: this.description,
                            return_type: this.cArgs.return_type
                        })
                        .then(res => {
                            
                            if(res.data == -1){
                                this.alert_class.openAlertWarning(this.trans('fp-change-unavailability-error', 177)).then(res => {});
                                this.$emit('update:result', -1); 
                            } else {

                                this.$emit('update:result', 1);
                                if (this.args.return_type == 2) {
                                    res.data.people[0].selected = selected;
                                    this.cReturn = res.data.people[0];
                                } else {
                                    this.cReturn = res.data.items;
                                }

                                if (this.profile != null) {
                                    this.cProfile.refresh++;
                                }
                                  
                            }
                            
                        })
                        .catch((error) => {
                            console.error(`error: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.computedShow = false;
                        })
                }});
        },

        roundDate(date){
            return moment(Math.round((+date) / (+moment.duration(10, "minutes"))) * (+moment.duration(10, "minutes"))); 
        },


        closeModal() {
            this.$emit("update:show", false);
        },




    },
};
</script>

<style scoped>

</style>
