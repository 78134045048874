<template>
    <div class="app-planner-blocks-container">
        <div class="app-planner-blocks">
            <div class="app-planner-blocks-timeline-day" v-for="(item, index) in dates" :key="item.id">
                    <planning-planner-unavailability-day-cell-component :day="item" :index="index" :person.sync="cPerson" :filters="filters" :read_only="read_only" :profile.sync="cProfile"/>            
                    <div 
                        class="app-planner-blocks-timeline-day-shifts" 
                        v-bind:class="{ 'app-planner-blocks-timeline-weekend': item.is_weekend, 
                                        'app-planner-blocks-timeline-day-border-right': index == 6,
                                        'app-planner-border-bottom-none': person.selected != true}" 
                       :style="{'min-height' : person.planning.settings.shifts_height + 'px'}"
                    />
                    <div v-if="person.selected == true" class="mt-2 app-planner-blocks-timeline-day-available" 
                        v-bind:class="{ 'app-planner-blocks-timeline-day-available-green' : person.planning.available[week_index] != null && person.planning.available[week_index].data[index] != null && person.planning.available[week_index].data[index].d == 1, 
                                        'app-planner-blocks-timeline-day-border-right': index == 6,
                                        'app-planner-read-only': read_only}"
                        @click="changeAvailability(person.planning.available[week_index].data, person.planning.available[week_index].data[index], 1)"
                    >
                        <p class="app-local-text align-middle" v-bind:class="{ 'app-local-text-green' : person.planning.available[week_index] != null && person.planning.available[week_index].data[index] != null && person.planning.available[week_index].data[index].d == 1}" 
                                data-toggle="tooltip" :title="trans('day-part-day',182)">D</p>
                    </div>

                    <div v-if="person.selected == true" class="app-planner-blocks-timeline-day-available" 
                        v-bind:class="{ 'app-planner-blocks-timeline-day-available-green' : person.planning.available[week_index] != null && person.planning.available[week_index].data[index] != null && person.planning.available[week_index].data[index].e == 1, 
                                        'app-planner-blocks-timeline-day-border-right': index == 6,
                                        'app-planner-read-only': read_only}"
                        @click="changeAvailability(person.planning.available[week_index].data, person.planning.available[week_index].data[index], 2)"
                    >
                        <p class="app-local-text" v-bind:class="{ 'app-local-text-green' : person.planning.available[week_index] != null && person.planning.available[week_index].data[index] != null && person.planning.available[week_index].data[index].e == 1}" data-toggle="tooltip" :title="trans('day-part-evening',182)">A</p>
                    </div>
                    
                    <div v-if="person.selected == true" class="mb-2 app-planner-blocks-timeline-day-available app-planner-border-bottom"
                        v-bind:class="{ 'app-planner-blocks-timeline-day-available-green' : person.planning.available[week_index] != null && person.planning.available[week_index].data[index] != null && person.planning.available[week_index].data[index].n == 1, 
                                        'app-planner-blocks-timeline-day-border-right': index == 6,
                                        'app-planner-read-only': read_only}"
                        @click="changeAvailability(person.planning.available[week_index].data, person.planning.available[week_index].data[index], 3)"
                    >
                        <p class="app-local-text" v-bind:class="{ 'app-local-text-green' : person.planning.available[week_index] != null && person.planning.available[week_index].data[index] != null && person.planning.available[week_index].data[index].n == 1}" data-toggle="tooltip" :title="trans('day-part-night',182)">N</p>
                    </div>

                    <div 
                        v-if="fromListPersonAdd && fromListPersonAdd.length > 0"
                        class="app-planner-blocks-timeline-day-registration-shifts" 
                        v-bind:class="{ 'app-planner-blocks-timeline-weekend': item.is_weekend, 
                                        'app-planner-blocks-timeline-day-border-right': index == 6,
                                        'app-planner-border-bottom-none': person.selected != true}" 
                       style="min-height: 32px"
                    />
                                
            </div>
                
            <div class="h-100 app-planner-blocks-timeline-summary">  
                <div tabindex="0" :id="'popover-week-statistics-' + _uid" class="" v-bind:class="{ 'app-planner-blocks-timeline-summary-selected' : person.selected == true}"> 
                    <div v-bind:class="{
                        'app-planner-blocks-timeline-summary-red': person.statistics.weeks[week_index].diff < 0, 
                        'app-planner-blocks-timeline-summary-green': person.statistics.weeks[week_index].diff >= 0
                        }" 
                        :style="{'min-height' : person.planning.settings.shifts_height + 13 + 'px'}"> {{person.statistics.weeks[week_index].diff.toString().replace('.', ',')}} </div> 
                </div>
                <div v-if="person.selected == true && !read_only" class="mt-2 app-planner-blocks-timeline-summary-menu d-flex align-items-center justify-content-center">
                    <b-dropdown variant="link" no-caret right toggle-class="app-planner-blocks-timeline-summary-menu-button" v-if="cEditedAvailability.item == null && person.planning.available[week_index].is_default == 0">
                        <template #button-content>
                            <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                        </template>
                        <b-dropdown-item @click="setAsDefault(person.planning.available[week_index])">{{trans('pl-set-as-default',210)}}</b-dropdown-item>
                    </b-dropdown> 
                </div>
            </div> 
            <planning-planner-unavailability-blocks-component v-for="item in person.planning.unavailable[week_index]" :key="item.id" :block="item" :minute_pixel_width="minute_pixel_width" :person.sync="cPerson" :filters="filters" :hovered_block.sync="cHoveredBlock" :read_only="read_only" :profile.sync="cProfile" />
            <planning-planner-shifts-blocks-component v-for="item in person.planning.shifts[week_index]" :key="item.id" :block="item" :minute_pixel_width="minute_pixel_width" :hovered_block.sync="cHoveredBlock" :read_only="read_only"/>                   
            <planning-planner-assignments-blocks-component v-for="item in person.planning.assignments[week_index]" :key="item.id" :block="item" :minute_pixel_width="minute_pixel_width" :shifts_count="person.planning.settings.shifts_count" :hovered_block.sync="cHoveredBlock" :read_only="read_only" />
            <planning-planner-shifts-register-blocks-component v-if="fromListPersonAdd && fromListPersonAdd.length > 0" :style="'margin-top:' + (person.planning.settings.shifts_height + 80) + 'px'" v-for="item in fromListPersonAdd" :key="item.id_shift_data_main" :block="item" :minute_pixel_width="minute_pixel_width" :read_only="read_only" :week_first_day="dates[0].date"/>                               

        </div>    

        <b-row v-if="cShowEditButton == true" class="mb-2 app-planner-blocks-timeline-summary-margin-right">
            <b-col>
                </b-col>
            <b-col cols="auto" end class="pr-0">
                <app-button class="app-planner-availability-button" type="secondary-grey" :disabled="available_loading_change" @click="cancelEditAvailability()">{{trans('cancel',175)}}</app-button>
                <app-button class="app-planner-availability-button" type="primary" @click="saveAvailability(person.planning.available[week_index].data)" :loading="available_loading_change"> {{trans('save',175)}} </app-button>
            </b-col>
        </b-row>
     
        <b-popover :target="'popover-week-statistics-' + _uid" triggers="focus">
            <template #title><p class="mb-0 app-popover-small-text font-weight-600">{{trans('pl-weekly-stats',173)}} </p></template>
            
            <b-row class="align-items-center">
                <b-col start>
                    <p class="app-popover-small-text mb-0">{{trans('planner-hours-per-week',173)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('sh-worked-hours',215)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('fp-unavailability',172)}}</p>
                </b-col>
                <b-col cols="auto" end>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{person.statistics.weeks[week_index].hours.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{person.statistics.weeks[week_index].worked.toString().replace('.', ',')}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{person.statistics.weeks[week_index].notavailable.toString().replace('.', ',')}}</p>
                </b-col>
            </b-row>

            <hr class="mt-1 mb-2" />

            <b-row class="align-items-center">
                <b-col start>
                    <p class="app-popover-small-text mb-0">{{trans('pl-diff',173)}}</p>
                </b-col>
                <b-col cols="auto" end>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{person.statistics.weeks[week_index].diff.toString().replace('.', ',')}}</p>
                </b-col>
            </b-row>
        </b-popover>
          
    </div>
      
</template>



<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerUnavailabilityBlocksComponent from './Unavailability/PlanningPlannerUnavailabilityBlocksComponent.vue';
import PlanningPlannerUnavailabilityDayCellComponent from './Unavailability/PlanningPlannerUnavailabilityDayCellComponent.vue';
import PlanningPlannerShiftsBlocksComponent from './PlanningPlannerShiftsBlocksComponent.vue';
import PlanningPlannerAssignmentsBlocksComponent from './PlanningPlannerAssignmentsBlocksComponent.vue';
import PlanningPlannerShiftsRegisterBlocksComponent from './PlanningPlannerShiftsRegisterBlocksComponent.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    name: 'PlanningPlannerPersonShiftsComponent',

    components: {
        PlanningPlannerUnavailabilityBlocksComponent,
        PlanningPlannerUnavailabilityDayCellComponent,
        PlanningPlannerShiftsBlocksComponent,
        PlanningPlannerShiftsRegisterBlocksComponent,
        PlanningPlannerAssignmentsBlocksComponent
    },

    computed: {  
        
        cPerson: {
            get() { return this.person },
            set(value) { this.$emit('update:person', value) }
        },
        
        cPopoverStatistics: {
            get() { return this.popover_statistics },
            set() { this.$emit('update:popover_statistics', false) }, 
        },

        cHoveredBlock: {
            get() { return this.hovered_block },
            set(value) { this.$emit('update:hovered_block', value) }, 
        },

        cEditedAvailability: {
            get() { return this.edited_availability },
            set(value) { this.$emit('update:edited_availability', value) }, 
        },

        cShowEditButton:{

            get(){

                if(this.edited_availability.item != null && this.edited_availability.id_person_data_main == this.person.person.id && this.edited_availability.week_index == this.week_index){
                    
                    if(JSON.stringify(this.person.planning.available[this.week_index]) != JSON.stringify(this.cEditedAvailability.item)){
                        return true;
                    }

                    return false;

                }else{
                    return false;
                }
            }

        },

        cRefresh: {
            get() { return this.refresh },
            set(value) { this.$emit('update:refresh', value) }
        },

        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    props:["profile", "person", "filters", "popover_statistics", "hovered_block", "edited_availability", "week_index", "dates", "minute_pixel_width", "read_only", "fromListPersonAdd"],

    mounted() {
    },

    data() {
        return {
           
            available_loading_change: false,
            alert_class: new AppAlerts(),
        };
    },
    
    methods: {

        checkReadOnly(event){
            
            if(this.read_only == true){
                event.preventDefault();
            }

        },
        
       changeAvailability(item, i, type){
        
        if(this.read_only == false){

            if(this.cEditedAvailability.item == null || 
            (this.edited_availability.id_person_data_main == this.person.person.id && this.edited_availability.week_index == this.week_index)){
            
                if(this.cEditedAvailability.item == null){
                    this.cEditedAvailability.week_index = this.week_index;
                    this.cEditedAvailability.item = JSON.parse(JSON.stringify(item));
                    this.cEditedAvailability.id_person_data_main = this.person.person.id;
                }
                

                if(type == 1){//day
                    if(i.d == 1){
                        i.d = 0;
                    }else{
                        i.d = 1;
                    }   
                }else if(type == 2){//evening
                    if(i.e == 1){
                        i.e = 0;
                    }else{
                        i.e = 1;
                    }
                }else if(type == 3){//night
                    if(i.n == 1){
                        i.n = 0;
                    }else{
                        i.n = 1;
                    }
                }

                if(JSON.stringify(this.person.planning.available[this.week_index].data) == JSON.stringify(this.cEditedAvailability.item)){
                    this.cEditedAvailability.week_index = null;
                    this.cEditedAvailability.item = null;
                    this.cEditedAvailability.id_person_data_main = null;
                }

            }

        }

       },

       saveAvailability(item){

        if(this.read_only == false){

            this.available_loading_change = true;
            axios
                .post('planning/planner/availability/change', {
                    id_person_data_main: this.person.person.id,
                    week_value: JSON.stringify(item),
                    date_from: this.dates[0].date,
                    date_to: this.dates[6].date,
                    filters: JSON.stringify(this.filters)
                })
                .then(res => {
                    res.data.people[0].selected = true;
                    this.cPerson = res.data.people[0];
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.cEditedAvailability.week_index = null;
                    this.cEditedAvailability.item = null;
                    this.cEditedAvailability.id_person_data_main = null;
                    this.available_loading_change = false;
                });

        }

       },

       cancelEditAvailability(){

            if(this.read_only == false){

                this.cPerson.planning.available[this.week_index].data = this.cEditedAvailability.item;

                this.cEditedAvailability.week_index = null;
                this.cEditedAvailability.item = null;
                this.cEditedAvailability.id_person_data_main = null;

            }
       },

       setAsDefault(item){

        if(this.read_only == false){

            this.alert_class.openAlertConfirmation(this.trans('confirm-message', 180)).then(res => {
                if(res == true){
                    //this.loading_list = true;

                    axios
                        .post('planning/planner/availability/setAsDefault', {
                            id_person_data_main: this.person.person.id,
                            date_from: this.dates[0].date,
                            date_to: this.dates[6].date,
                            filters: JSON.stringify(this.filters)
                        })
                        .then(res => {
                            res.data.people[0].selected = true;
                            this.cPerson = res.data.people[0];
                        })
                        .catch((error) => {
                            console.error(`error: ${error}`);
                        })
                        .finally(() => {
                            //this.loading_list = false;
                        });
                }
            });

        }

       }
    }
};
</script>

<style scoped>
.app-local-text{
    font-size: 10px;
    color: #D0D5DD;
    margin: auto;
    text-align: center;
    line-height: 14px;
}

.app-local-text-green {
     color: #FFFFFF;
}

.tooltip{
    opacity: 1 !important;
}
</style>
