<template>
   <div class="app-planner-blocks-timeline-day-unavailable" 
                 v-bind:class="{ 'app-planner-blocks-timeline-weekend': day.is_weekend, 
                                 'app-planner-blocks-timeline-day-border-right': index == 6,
                                 'app-planner-read-only': read_only}" @click="onAdd(day.date)">
   
   <planning-planner-unavailability-add v-if="mAdd.show" :show.sync="mAdd.show" :args.sync="mAdd.args" :result.sync="mAdd.result" :return.sync="cPerson" :profile="cProfile"/>
   </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerUnavailabilityAdd from './PlanningPlannerUnavailabilityAdd.vue';

export default {
    name: 'PlanningPlannerUnavailableDayCellComponent',

    components: {
        PlanningPlannerUnavailabilityAdd
    },

    created(){
       
    },

    computed: {       
        cPerson: {
            get() { return this.person },
            set(value) { this.$emit('update:person', value) }
        },

         cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    props:["day", "index", "person", "filters", "read_only", "profile"],
    
    watch:{
        
    },

    mounted() {
    
    },

    data() {
        return {
            mAdd: { show: false, args: { id_person_data_main: null, selected_date: null}, result: 0, return: null},
            
        };
    },
    
    methods: {
         onAdd(selected_date){

            if(this.read_only == false){

                this.mAdd.args.filters = this.filters;
                this.mAdd.args.id_person_data_main = this.person.person.id;  
                this.mAdd.args.selected_date = selected_date;
                this.mAdd.args.return_type = 2;
                this.mAdd.show = true;

            }
        },
    }
};
</script>

<style scoped>

.app-local-person-function{
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.app-local-person-data{
    margin-top:2px;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-avatar{
    margin-top:4px;
    height: 30px;
    width: 30px;
}
</style>
