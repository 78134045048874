<template>
    <div class="app-planner-header">
        <div v-if="isWeekHeader" v-bind:class="{'app-planner-header-week': week.is_selected == false, 'app-planner-header-week-selected': week.is_selected == true, 'app-planner-read-only': read_only }"> {{ week.label }}</div>
        <div class="app-planner-header-timeline" :id="'weekContainer_' + week.index" :ref="'weekContainer_' + week.index">
                        
            <div class="app-planner-header-timeline-day"  
                 v-bind:class="{
                                
                                'app-planner-header-timeline-weekend': item.is_weekend, 
                                'app-planner-header-timeline-day-border-right': index == 6, 
                                'app-planner-header-timeline-day-border-top': !isWeekHeader }" 
                 v-for="(item, index) in week.dates" :key="item.id">
                <div class="pt-2 font-weight-600">{{item.day_name}}</div>
                <div> {{ item.short_date}} </div>
            </div>
            
        </div>
    </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";

export default {
    name: 'PlanningPlannerWeekComponent',

    components: {
    },

    created() {
       
    },

    computed: {       
        cMinutePixelWidth: {
            get() { return this.minute_pixel_width },
            set(value) { this.$emit('update:minute_pixel_width', value) }
        },

        cSelectedWeek: {
            get() { return this.selected_week },
            set(value) { this.$emit('update:selected_week', value) }
        }
    },

    props:["week", "minute_pixel_width", "isWeekHeader", "selected_week", "read_only", "destination"],
    
    watch:{
        
    },

    mounted() {
        this.resizeEvent = new ResizeObserver(this.getHeaderWidth).observe(this.$refs['weekContainer_' + this.week.index]);
    },

     beforeDestroy () {
        if(this.$refs['weekContainer_' + this.week.index] && this.resizeEvent){
            this.resizeEvent.unobserve(this.$refs['weekContainer_' + this.week.index])
        }
    },

    data() {
        return {
          
        };
    },
    
    methods: {
        getHeaderWidth() {
            if(this.$refs['weekContainer_' + this.week.index]){
                this.cMinutePixelWidth = this.$refs['weekContainer_' + this.week.index].clientWidth / 10080 ;
            }
            
        },

        changeWeek() {
            if(!this.read_only){

                if (this.week.is_selected == false) {
                    this.cSelectedWeek = this.week.week
                }

            }
            
        }
    }
};
</script>
