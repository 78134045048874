<template>
    <div tabindex="0" :id="'popover-shift-' + _uid" class="app-planner-shifts-block" 
         v-bind:class="{
                        'app-planner-shifts-block-focus' : hovered_block != null && hovered_block.type == 2 && hovered_block.id == block.id,
                        'app-planner-shifts-block-unconfirm' : block.status_id == 1,
                        'app-planner-shifts-block-confirm' : block.status_id == 2,
                        'app-planner-shifts-block-worked' : block.status_id == 3,
                        'app-planner-shifts-block-registration-success' : block.status_id == 4,
                    }" 
         :style="{'width': ((block.width * minute_pixel_width)) + 'px', 'left': (block.left * minute_pixel_width) + 'px'}" 
         @mouseover="setHoverd" 
         @mouseleave="clearHovered"> 


        <b-popover :target="'popover-shift-' + _uid" triggers="focus" @show="getShiftData">
            <template #title>
                 <p class="mb-0 app-popover-small-text font-weight-600">Dienst</p>
            </template>            
            <div class="text-center mt-2 mb-3 text-muted" v-if="loading">
                Gegevens worden geladen..
            </div>
            <b-row class="align-items-center" v-else>
                <b-col cols="5" class="pr-0" start>
                    <p class="app-popover-small-text mb-2">{{trans('status',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-from',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-to',3)}}</p>
                    <p class="app-popover-small-text mb-2">{{trans('function',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('department',182)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('location',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('client',5)}}</p>
                    
                </b-col>
                <b-col cols="7" class="pl-0" end>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-2">{{ shift != null ? shift.status_name : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ shift != null ? shift.date_from : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ shift != null ? shift.date_to : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-2">{{ shift != null ? shift.function_name : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ shift != null ? shift.department_name : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0 ">{{ shift != null ? shift.location_name : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ shift != null ? shift.client_name : ''}}</p>
                </b-col>
            </b-row>
            
            <hr class="mt-1 mb-2" />

            <div class="text-center">
                <app-button type="secondary-grey" :url="{ name: 'planning-shifts-profile', params: { id_shift_data_main: block.id }}" :loading="loading">{{trans('to-service',200)}}</app-button>
            </div>
            
        </b-popover>  
        
    </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";

export default {
    name: 'PlanningPlannerBlocksShiftsComponent',
    
    computed: { 

         cHoveredBlock: {
            get() { return this.hovered_block },
            set(value) { this.$emit('update:hovered_block', value) }, 
        },
    },


    props:["block", "minute_pixel_width", "hovered_block", "read_only"],
    
    data() {
        return { 
            shift: null,
            loading: false,
            shift_height:19,         
        };
    },
    
    methods: {

        setHoverd() {
            this.cHoveredBlock.type = 2;
            this.cHoveredBlock.id = this.block.id;
        },

        clearHovered() {
            this.cHoveredBlock.type = null;
            this.cHoveredBlock.id = null;
        }, 
        
        getShiftData() {
            this.loading = true;
            this.shift = null;
            axios
                .get('planning/planner/getShiftPopover/'+ this.block.id)
                .then((res) => {
                    this.shift = res.data
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        openShift() {
            this.$router.push({ name: "planning-shifts-profile", params: { id_shift_data_main: this.block.id } });
        }

          
    }
};
</script>

<style scoped>

</style>
