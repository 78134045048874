<template>
    <div tabindex="0" :id="'popover-unavailability-' + _uid" class="app-planner-blocks-unavailable" v-bind:class="{'app-planner-blocks-unavailable-focus' : hovered_block != null && hovered_block.type == 1 && hovered_block.id == block.id}" :style="{'width': ((block.width * minute_pixel_width)) + 'px', 'left': (block.left * minute_pixel_width) + 'px'}" @mouseover="setHoverd" @mouseleave="clearHovered"> 
        <b-popover :target="'popover-unavailability-' + _uid" triggers="focus">
            <template #title><p class="mb-0 app-popover-small-text font-weight-600">{{trans('fp-unavailable', 172)}} </p></template>            
            <b-row class="align-items-center">
                <b-col start>
                    <p class="app-popover-small-text mb-0">{{trans('spieces',182)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-from',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-to',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('sh-hours',215)}}</p>
                </b-col>
                <b-col cols="auto" end>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{block.type}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{block.date_from}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{block.date_to}}</p>
                    <p class="app-popover-small-text font-weight-600 mb-0 text-right">{{block.unavailability_hours}}</p>
                </b-col>
            </b-row>
            <b-alert variant="info" show class="mb-0 p-1 mt-2" v-if="block.description != null">
                <p class="m-1 app-popover-small-text font-weight-400"> {{block.description}}</p>
            </b-alert>
                    

            <hr class="mt-1 mb-2" v-if="!read_only" />

           
             <b-row class="align-items-center" v-if="!read_only">
                <b-col cols="auto" class="mr-auto">
                   <app-button type="secondary-grey" @click="remove(block)">{{trans('remove', 175)}}</app-button>
                </b-col>
                <b-col cols="auto">
                    <app-button type="secondary-grey" @click="onChange(block)">{{trans('change',175)}}</app-button>
                </b-col>
            </b-row>
            

        </b-popover>
        <planning-planner-unavailability-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="cPerson" :profile="cProfile"/>
    
    </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerUnavailabilityChange from './PlanningPlannerUnavailabilityChange.vue';
import AppAlerts from '@core/scripts/AppAlerts';


export default {
    name: 'PlanningPlannerUnavailabilityBlocksComponent',

    components: {
        PlanningPlannerUnavailabilityChange
    },

    created(){
       
    },

    computed: {       
        
        cRefresh: {
            get() { return this.refresh },
            set(value) { this.$emit('update:refresh', value) }
        },

        cPerson: {
            get() { return this.person },
            set(value) { this.$emit('update:person', value) }
        },

        cHoveredBlock: {
            get() { return this.hovered_block },
            set(value) { this.$emit('update:hovered_block', value) }, 
        },

        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    props:["profile", "person", "filters", "block", "minute_pixel_width", "hovered_block", "read_only"],
    
    watch:{
        "mChange.result": {
            handler: function(val){
                if(val == 1){
                    this.mChange.result = 0;
                    this.mChange.return = null;
                    this.cRefresh ++;
                }
            }
        },
    },

    mounted() {
        
    },

    data() {
        return {
           mChange: { show: false, args: {
                id_person_data_main: null,
                filters: null,
                date_from: null,
                date_to: null,
                type: { name: null, value: null },
                description: null,
                id_unavailability_data_main: null,
                hours_amount: null
            }, return: null },
            alert_class: new AppAlerts()
        };
    },
    
    methods: {

        setHoverd() {
            this.cHoveredBlock.type = 1;
            this.cHoveredBlock.id = this.block.id;
        },

        clearHovered() {
            this.cHoveredBlock.type = null;
            this.cHoveredBlock.id = null;
        },

        onRemove(id) {

        },

        onChange(item){
            this.mChange.args.filters = this.filters;
            this.mChange.args.id_person_data_main = this.person.person.id;            
            this.mChange.args.date_from = moment(item.date_from,"DD-MM-YYYY").format("DD-MM-YYYY");
            this.mChange.args.date_to = moment(item.date_to, "DD-MM-YYYY").format("DD-MM-YYYY");
            this.mChange.args.hour_from = moment(item.date_from, "DD-MM-YYYY HH:mm").format("HH:mm");
            this.mChange.args.hour_to = moment(item.date_to, "DD-MM-YYYY HH:mm").format("HH:mm");
            this.mChange.args.type.name = item.type;
            this.mChange.args.type.value = item.type_id;
            this.mChange.args.description = item.description;
            this.mChange.args.hours_amount = item.hours_amount;
            this.mChange.args.id_unavailability_data_main = item.id;
            this.mChange.args.return_type = 2;
            this.mChange.show = true;
        },

        remove(item){
            var selected = this.person.selected;
            this.alert_class.openAlertConfirmation(this.trans('confirm-message', 180)).then(res => {
                if(res == true){
                    this.loading_list = true;

            axios
                .post('planning/planner/unavailability/remove', {
                    id_person_data_main: this.person.person.id,
                    filters: JSON.stringify(this.filters),
                    id_unavailability_data_main: item.id,
                    return_type: 2
                })
                .then(res => {
                    res.data.people[0].selected = selected;
                    this.cPerson = res.data.people[0];
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    
                    if(this.profile != null) {
                        this.cProfile.refresh++;
                    }
                
                })
                }
            })
        }

        
    }
};
</script>

<style scoped>

.app-local-person-function{
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.app-local-person-data{
    margin-top:2px;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom-avatar{
    margin-top:4px;
    height: 30px;
    width: 30px;
}
</style>
