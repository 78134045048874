<template>
    <div>
        <div tabindex="0" :id="'popover-assignment-' + _uid" class="app-planner-assignments-block"
            v-bind:class="{
                            'app-planner-assignments-block-focus' : hovered_block != null && hovered_block.type == 3 && hovered_block.id == block.id,
                            'app-planner-assignments-block-unconfirm' : block.status_id == 1,
                            'app-planner-assignments-block-confirm' : block.status_id == 2,
                            'app-planner-assignments-block-worked' : block.status_id == 3,
                            'app-planner-assignments-block-registration-success' : block.status_id == 4,
                        }" 
            :style="{ 'top': ((block.row - 1) * 26) + (shifts_count > 0 ? 32 : 0 )  + 'px', 'width': (block.width * minute_pixel_width) + 'px', 'left': (block.left * minute_pixel_width)  + 'px'}" 
            @mouseover="setHoverd" 
            @mouseleave="clearHovered" />
        <planning-planner-shifts-blocks-component :style="{'top': ((block.row - 1) * 26) + (shifts_count > 0 ? 32 : 0 ) + 2  + 'px', 'height': '18px' }" v-for="item in block.shifts" :key="item.id" :block="item" :minute_pixel_width="minute_pixel_width" :hovered_block.sync="cHoveredBlock" />                   
        
        <b-popover :target="'popover-assignment-' + _uid" triggers="focus" @show="getAssignmentData">
            <template #title><p class="mb-0 app-popover-small-text font-weight-600">{{trans('detachment',182)}}</p></template>            
            
            <div class="text-center mt-2 mb-3 text-muted" v-if="loading">
                {{trans('planning-loading-data',211)}}
            </div>
            <b-row class="align-items-center" v-else>
                <b-col cols="5" class="pr-0" start>
                    <p class="app-popover-small-text mb-2">{{trans('status',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-from',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('time-to',3)}}</p>
                    <p class="app-popover-small-text mb-2">{{trans('function',3)}}</p>
                    <p v-for="(item, index) in cDepartments" :key="index" class="app-popover-small-text mb-0"> <span v-if="index == 0"> {{trans('pl-departments',211)}} </span> <span v-else>  <br /></span></p>                    
                    <p class="app-popover-small-text mb-0">{{trans('location',3)}}</p>
                    <p class="app-popover-small-text mb-0">{{trans('client',5)}}</p>
                    
                </b-col>
                <b-col cols="7" class="pl-0" end>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-2">{{ assignment != null ? assignment.status_name : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ assignment != null ? assignment.date_from : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ assignment != null ? assignment.date_to : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-2">{{ assignment != null ? assignment.function_name : ''}}</p>
                    <p v-overflow-tooltip v-for="(item, index) in cDepartments" :key="index" class="app-popover-small-text font-weight-600 mb-0"> {{item}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0 ">{{ assignment != null ? assignment.location_name : ''}}</p>
                    <p v-overflow-tooltip class="app-popover-small-text font-weight-600 mb-0">{{ assignment != null ? assignment.client_name : ''}}</p>
                </b-col>
            </b-row>
            
            <hr class="mt-1 mb-2" />

            <div class="text-center">
                <app-button type="secondary-grey" :url="{ name: 'planning-assignments-profile', params: { id_assignment_data_main: block.id }}" :loading="loading">{{trans('pl-to-secondment',210)}}</app-button>
            </div>
            
        </b-popover>  
        
    </div>
</template>



<script>
import axios from "@axios";
import moment from "moment";
import PlanningPlannerShiftsBlocksComponent from './PlanningPlannerShiftsBlocksComponent.vue';

export default {
    name: 'PlanningPlannerAssignmentsShiftsComponent',

    components: {
        PlanningPlannerShiftsBlocksComponent
        
    },

    created(){
       
    },

    computed: {       
        
        cRefresh: {
            get() { return this.refresh },
            set(value) { this.$emit('update:refresh', value) }
        },

         cHoveredBlock: {
            get() { return this.hovered_block },
            set(value) { this.$emit('update:hovered_block', value) }, 
        },

        cDepartments: {
            get() { return this.assignment != null ? this.assignment.department_names.split(';') : ''; },
        },
    },

    props:["block", "shifts_count", "minute_pixel_width", "hovered_block", "read_only"],
   
    data() {
        return {
            assignment: null,
            loading: false         
        };
    },
    
    methods: {

         setHoverd() {
            this.cHoveredBlock.type = 3;
            this.cHoveredBlock.id = this.block.id;
        },

        clearHovered() {
            this.cHoveredBlock.type = null;
            this.cHoveredBlock.id = null;
        },

        getAssignmentData() {
            this.loading = true;
            this.shift = null;
            axios
                .get('planning/planner/getAssignmentPopover/'+ this.block.id)
                .then((res) => {
                    this.assignment = res.data
                })
                .catch((error) => {
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        openAssignment() {
            this.$router.push({ name: "planning-assignments-profile", params: { id_assignment_data_main: this.block.id } });
        }
    }
};
</script>

<style scoped>

</style>
